<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="6">
					<Heading size="6" uppercase>What we do</Heading>
					<Heading size="3">We Build Very Strong Durable Design Solutions</Heading>
				</GridContainer>
				<GridContainer size="6">
					<Paragraph>Condimentum id venenatis a condimentum vitae sapien pellentesque. Nec feugiat nisl pretium fusce id. Quam lacus suspendisse faucibus interdum posuere lorem. Hendrerit gravida rutrum quisque non tellus orci ac auctor. A diam sollicitudin.</Paragraph>
					<Paragraph>Condimentum id venenatis a condimentum vitae sapien pellentesque. Nec feugiat nisl pretium fusce id. Quam lacus suspendisse faucibus interdum posuere lorem. Hendrerit gravida rutrum quisque non tellu.</Paragraph>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="light">
			<Grid>
				<GridContainer size="12 6@sm 4@md">
					<Images width="100%" height="100%" image="theme-wm/home3.jpg" />
				</GridContainer>
				<GridContainer size="12 6@sm 5@md">
					<Card type="base">
						<CardSection>
							<Stack direction="column" align="left" space="3">
								<Heading size="3">Read About Our Work</Heading>
								<Stack direction="column" algin="left" space="1">
									<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nosa nostra, per inceptos himenaeos. Maur is in erat justo. Nullamiac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Maur is in erat justo. Nullam ac litora lorem ipsum dolor sit amet.</Paragraph>
									<Paragraph>Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue.</Paragraph>
								</Stack>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 3@md" align="middle">
					<Stack direction="column" align="left" space="2">
						<Heading size="5">Data &amp; Analysis</Heading>
						<Heading size="5">Operations &amp; Efficiency</Heading>
						<Heading size="5">Digitalization &amp; IT</Heading>
						<Heading size="5">Leadership &amp; Change</Heading>
						<Heading size="5">Growth &amp; Innovation</Heading>
						<Heading size="5">Branding &amp; Design</Heading>
						<Heading size="5">Management &amp; Plan</Heading>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 4@sm 2@md">
					<Stack direction="column" align="left right@sm" space="1">
						<Heading size="1">$70</Heading>
						<Heading size="6" uppercase>MONTHLY</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 7@sm 2@md">
					<Stack direction="column" align="left" space="1">
						<Heading size="4">Business Package</Heading>
						<Paragraph>Nullam ac urna eu Ut enim ad minim Lorem ipsum dolor Cosectetur adipisic Nullam ac urna eu</Paragraph>
						<Button label="Read More" color="secondary" size="small" type="outline" />
					</Stack>
				</GridContainer>
				<GridContainer size="12 4@sm 2@md">
					<Stack direction="column" align="left right@sm" space="1">
						<Heading size="1">$98</Heading>
						<Heading size="6" uppercase>MONTHLY</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 7@sm 2@md">
					<Stack direction="column" align="left" space="1">
						<Heading size="4">Deluxe Package</Heading>
						<Paragraph>Nullam ac urna eu Ut enim ad minim Lorem ipsum dolor Cosectetur adipisic Nullam ac urna eu</Paragraph>
						<Button label="Read More" color="secondary" size="small" />
					</Stack>
				</GridContainer>
				<GridContainer size="12 4@sm 2@md">
					<Stack direction="column" align="left right@sm" space="1">
						<Heading size="1">$40</Heading>
						<Heading size="6" uppercase>MONTHLY</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 7@sm 2@md">
					<Stack direction="column" align="left" space="1">
						<Heading size="4">Personal Package</Heading>
						<Paragraph>Nullam ac urna eu Ut enim ad minim Lorem ipsum dolor Cosectetur adipisic Nullam ac urna eu</Paragraph>
						<Button label="Read More" color="secondary" size="small" type="outline" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Heading size="3">Have a project or idea? <Link text="Drop us a line." display="inline" url="/windermere/contact" /></Heading>
		</Section>

	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
